.estatisticas-container{
    display: flex;
    padding: 60px 20px;
    gap: 20px;
    z-index: 10;
    position: relative;
    max-width: 1150px;
    
    margin: auto;
}

.estatisticas-header div{
    flex: 0;
}

.estatisticas-wrapper{

    position: relative;
}

.home-bottom{
    height: 50%; /* Altura desejada da segunda div */
      width: 100%; /* Ocupa toda a largura da div anterior */
      position: absolute;
      bottom: 0; /* Move para a metade da altura da div anterior */
      z-index: 1;
}

@media (max-width: 1000px){
    .estatisticas-container{
        flex-direction: column;
        max-width: 500px;
    }
}

.estatisticas-header{
    display: flex;
    align-items: center;
    gap: 7px;

    margin-bottom: 20px;
}

.card-container{
    background-color: #fff;
    padding: 40px 40px;
    border-radius: 12px;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.3);
    flex: 1;
}

.card-container h4{
    font-size: 24px;
    color: #735645;
}

.card-container p{
    color: #545454;
}

