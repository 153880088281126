/* Adicione estas regras de estilo para o modal */

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    padding: 0 10px;
  }
  
  .modal {
    background: #fff;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    max-width: 600px;
    position: relative;
    z-index: 10;
    margin: auto;
    max-height: 60vh;
    overflow: scroll;
  }

/* Adicionando estilos específicos para o modal */
.modal::-webkit-scrollbar {
  width: 10px; 
  border-radius: 5px;/* Largura da barra de rolagem no modal */
}

.modal::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #ccbd9b; /* Cor do "ponteiro" no modal */
}
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 30px;
    
    cursor: pointer;
    color: #333;
  }
  
  .modal h2 {
    margin-bottom: 5px;
  }
  
  .modal p {
    margin-bottom: 15px;
    font-size: 18px;
    line-height: 1.5;
  }
  
  .modal ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .modal li {
    margin-bottom: 10px;
  }
  
  .modal a {
    color: #0b0b0b;
    text-decoration: underline;
  }
  
  .fechar-btn {
    background-color: #ccbd9b;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .fechar-btn:hover {
    background-color: #e6d2a3;
  }
  
  @media (max-width: 450px) {
    .modal{
      width: 95%;
    }
  }