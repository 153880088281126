* {
  margin: 0;
  padding: 0;
  line-height: 1;
  box-sizing: border-box;
}

@font-face {
  font-family: "futura-pt-bold";
  src: url("./assets/fonts/Futura/FuturaPTBold.otf") format("opentype");
}

@font-face {
  font-family: "futura-pt-light";
  src: url("./assets/fonts/Futura/FuturaPTLight.otf") format("opentype");
}

@font-face {
  font-family: "futura-pt-medium";
  src: url("./assets/fonts/Futura/FuturaPTMedium.otf") format("opentype");
}

@font-face {
  font-family: "futura-pt-regular";
  src: url("./assets/fonts/Futura/FuturaPTMedium.otf") format("opentype");
}
a {
  text-decoration: none;
  color: #fff;
}

.header-container .menu-toggle img {
  width: 100%;
  height: auto;
}

a,
p {
  font-family: "futura-pt-light";
  font-size: 20px;
}

.main-btn {
  font-family: "futura-pt-light";
  font-size: 14px;
  display: block;
  height: 48px;
  background: #ee9982;
  background-color: #ccbd9b;
  color: #fff;
  padding: 0 27px;
  cursor: pointer;
  width: fit-content;
  text-transform: none !important;
  letter-spacing: 1px;
}

.carrousel-btn {
  margin: auto;
  margin-top: 30px;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Playfair Display", serif;
}

button {
  border: none;
  cursor: pointer;
}

.titulo-secoes {
  font-family: "Prata", serif;
}

.App {
  font-family: "Playfair Display", serif;
}
.body-container {
  max-width: 1150px;
  margin: auto;
}

.header-body-footer-width {
  margin: auto;
}

.header-body-footer-width .main-btn {
  font-size: 14px;
}

@media (max-width: 630px) {
  .header-body-footer-width {
    padding: 60px 20px !important;
  }
  .header-container {
    padding: 0 20px !important;
  }
}
