.storymap-content,
.titulos-storymap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
}
.storymap-content {
  margin-top: 80px;
}

.div-linha {
  padding: 10px 10px 40px 10px;
}

.titulos-storymap {
  gap: 10px;
}
.titulos-storymap h1 {
  font-size: 35px;
  color: #97896b;
  text-align: center;
}

.titulos-storymap p {
  color: #343434;
  font-size: 20px;
  max-width: 600px;
  margin-bottom: 20px;
  text-align: center;
}

/* Estilo da div que renderiza site externo */
.site-storymap {
  width: 100%;

  max-width: 1000px;
  height: 500px;
}

.site-storymap iframe {
  width: 100%;
  height: 100%;
}

.map-btn {
  background: #ee9982;
  background-color: #ccbd9b;
  color: #fff;
  height: 48px;
  line-height: 48px;
  padding: 0 27px;
  letter-spacing: 1px;
  font-size: 14px;
  font-family: "futura-pt-light";
  margin-top: 25px;
}

@media (max-width: 768px) {
  .site-mapa {
    margin-left: 20px;
    width: 500px;
    height: 300px;
  }
  .site-mapa iframe {
    width: 80%;
    height: 100%;
  }

  .titulos-storymap{
    padding: 0;
  }
}
