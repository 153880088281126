@font-face {
  font-family: "futura-pt-light";
  src: url("../fonts/Futura/FuturaPTLight.otf") format("opentype");
}

.home-container {
  display: flex;
  padding-top: 60px;
  margin-top: 90px;
  gap: 40px;
  background-image: url("../imgs/maceio1.jpg");
  min-height: 750px;
}

.home-header {
  background-color: #ccbd9b;
  padding: 20px;
  border-radius: 10px;
  max-width: 900px;
  margin: 0px auto;
  height: fit-content;
  margin-bottom: 220px;
}

@media (max-width: 1000px) {
  .home-header {
    margin-bottom: 20px;
  }
}

.text-highlight {
  color: #654600;
}

.home-bottom {
  background-color: #ccbd9b;
}

@media (max-width: 768px) {
  .home-container {
    flex-direction: column;
  }
}

.home-container div {
  flex: 1;
}

.home-img {
  width: 100%;
  height: auto;
}
.home-content {
  display: flex;
  flex-direction: column;
  position: relative;
}

.home-content h3 {
  color: #343434;
  font-size: 35px;
}

.subtitle {
  font-size: 26px;
}

.home-content p {
  color: #535353;
  font-size: 20px;
}



@media (max-width: 450px) {
  .home-content h3 {
    font-size: 30px;
  }
}

@media (max-width: 768px) {
  .home-container {
    margin-top: 80px;
  }
}
@media (max-width: 950px) {
  .home-header {
    border-radius: 0;
  }
}

@media (max-width: 380px) {
  .home-container {
    margin-top: 60px ;
  }
}