.conteudo{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 30px;
    text-align: center;
}

.conteudo p{
    max-width: 400px;
    text-align: center;
}


.titulos-escritorio{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.fale-conosco{
    display: flex;
    align-items: center;
    gap: 20px;
    background-color: rgb(253, 249, 244);
    padding: 15px;
    border-radius: 5px;
}

.numero{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    font-weight: 400;
}
