

.testimonials-wrapper {
    background-color: #ffffff;
    overflow: hidden;
    width: 100%;
  }
  
  .testimonials-container h3 {
    font-size: 35px;
    color: #97896B;
    text-align: center;
    margin-bottom: 20px;
  }

  
  .testimonials-container {
    max-width: 1250px;
    margin: auto;
    padding: 60px 0 0 0;
    width: 90%;
  
  }
  .slick-list {margin: 0 -5px;}
  .slick-slide>div {padding: 5px;}
  
  .testimonials-container .carousel-item{
    margin: 10px;
  }
  
  .testimonials-container h3{
    margin-bottom: 10px;
  }
  
  .carousel-child{
    margin: 10px;
  }
  
  .testimonials-item {
    background-color: #ccbd9b30;
    height: 400px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    cursor: grab;
    overflow: hidden;
  }

  .testimonials-item:active{
    cursor: grabbing;
  }

  
  .testimonials-container .custom-arrow{
    top: 180px;
    color: #735645;
  }
  
  @media (max-width: 768px) {
  
    .testimonials-container {
      width: 80%;
    
    }
  }

.custom-arrow{
    font-size: 50px;
    width: fit-content;
    position: absolute;
    top: 210px;
    cursor: pointer;
}

.custom-arrow.custom-prev-arrow{
    left: -50px;
}

.custom-arrow.custom-next-arrow{
    right: -50px;
}

@media (max-width: 450px){
    .custom-arrow.custom-prev-arrow{
        left: -35px;
    }
    
    .custom-arrow.custom-next-arrow{
        right: -35px;
    
    }

    .custom-arrow{
        font-size: 40px;
    }

    .carousel-container{
        width: 80%;
    }
    
}

.testimonials-wrapper .slick-dots li button:before, .testimonials-wrapper .slick-dots li.slick-active button:before {
color: #97896B;
}

/* ========== Depoimentos GB ========= */



.testimonials-item .testimonials{
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 20px;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: calc(100% - 40px);
}