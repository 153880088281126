.contato-container {
  display: flex;
  max-width: 90%;
  color: #343434;
  margin: auto;
  margin-bottom: 50px;
  gap: 20px;
}

.contato-wrapper {
  margin-top: 100px;
}

.contato-img {
  width: 128px;
  height: 128px;
  border-radius: 50%;
  margin: auto;
  margin-bottom: 40px;
  background-color: rgb(253, 249, 244);
  display: flex;
  justify-content: center;
  align-items: center;
}

.contato-container .right-col {
  text-align: center;
  max-width: 450px;
  gap: 5px;
  display: flex;
  flex-direction: column;
  height: fit-content;
}

.contato-container .contato-col {
  flex: 1;
}

.contato-container label {
  font-size: 14px;
}

.contato-container form {
  display: flex;
  flex-direction: column;
}
.contato-container input,
.contato-container textarea {
  border: 1px solid rgba(0, 0, 0, 0.07);
  padding: 12px 20px;
  margin-bottom: 10px;
  color: #343434;
  font-size: 14px;
  font-weight: 400;
  background: #fafafa;
  max-width: 100%;
  outline: 0;
  font-family: inherit;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.contato-container textarea {
  height: 150px;
}

@media (max-width: 860px) {
  .contato-container {
    flex-direction: column;
    align-items: center;
  }

  .contato-container form {
    margin: auto;
    width: 100%;
    max-width: 500px;
  }

  .contato-container .contato-col {
    width: 100%;
  }
}
