.acoes-titulo {
  text-align: center;
  padding: 60px 20px 0px 20px;
}

.acoes-titulo h1 {
  font-size: 35px;
  color: #97896b;
  text-align: center;
}

.acoes-titulo p {
  color: #343434;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-size: 22px;
}

.triangulo-1 {
  position: absolute;
  top: -20px;
  right: 20px;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid #ccbd9b30;
  z-index: 0;
}

.timeline-sessão {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px;
  gap: 25px;
  margin-top: 20px;
}

.sessão-tl {
  display: flex;
  flex-direction: column;
}
.sessão-tl button {
  justify-content: right;
}

.timeline-content {
  min-width: 400px;
  flex: 1;
  
  background-color: #ccbd9b30;
  padding: 20px;
  position: relative;
}

.numeros-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.texto-tl {
  font-size: 18px;
  line-height: 1.5;
}

.saibamais-btn {
  margin-top: 10px;
  max-width: 125px;
  padding: 5px;
  background-color: #ccbd9b;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

@media (max-width: 450px) {
  .timeline-content {
    min-width: calc(100% - 40px);
  }

  .timeline-sessão{
    gap: 15px;
    padding: 10px;
  }

  .triangulo-1 {
    position: absolute;
    top: -10px;
    right: 10px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #ccbd9b30;
    z-index: 0;
}
}
