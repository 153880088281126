/* Background */

.newsletter {
  border-radius: 15px;
  background-color: #ccbd9b30 !important;
  padding: 40px;
  display: flex;
  flex-direction: column;
  max-width: 550px;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
}

.news-btn{
    display: flex;
    color: rgba(255,255,255,0.8);
    align-items: center;
    gap: 10px;
    justify-content: space-between;
}

.news-btn p{
    font-size: 14px;
}



.newsletter-titulo h1 {
  color: #343434;
  font-size: 30px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.img-news{
    width: 50px;
}

.newsletter-titulo p {
    color: #343434;
  font-size: 20px;
  margin-bottom: 5px;
}

/* Form da newsletter*/

.news-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.news-form input[type="text"] {
    border: 1px solid rgba(0,0,0,0.07);
    padding: 15px 20px;
    font-size: 14px;
    font-weight: 300;
    background: #ccbd9b37;
    border-color: rgba(255,255,255,0.15);
    font-size: 16px;
    color: #c1c1c1;
    outline: 0;
    font-family: 'futura-pt-light';
    box-shadow: none;
    width: calc(100% - 40px);
}

.news-form button {
    height: 48px;
    width: 50%;
}

.news-btn p{
    display: flex;
    align-items: center;
    gap: 5px;
}

.news-btn p input{
    height: 15px;
    width: 15px;

}

@media (max-width: 450px){
    .newsletter{
        padding: 30px;
        margin: 80px 5px 50px 5px;
        
    }

    .newsletter .news-btn{
        flex-direction: column;
        align-items: flex-end;
    }


}